import React from "react"
import { ADD_MODE, useFormMode } from "../components/form"
import { AddSchedaCliente, EditSchedaCliente, ViewSchedaCliente } from "../components/scheda"

const SchedaClientePage = ({location}) => {
    const id = location.state?.id || null
    const mode = location.state?.mode || ADD_MODE
    
    const Add = () => <AddSchedaCliente/>
    const Edit = () => <EditSchedaCliente id={id}/>
    const View = () => <ViewSchedaCliente id={id}/>

    const Form = useFormMode(
        Add,
        Edit,
        View,
        mode
    )

    return <Form/>
}

export default SchedaClientePage
